<template>
	<div ref="container" class="basicDataClass">
		<Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch">
			<div>卫生资源</div>
		</Title>
		<div class="main">
			<div v-if="serviceIndex == 2 || serviceIndex === 1">
				<div style="height: 220px" element-loading-text="加载中..." element-loading-background="rgba(0, 0, 0, 0.4)" v-loading="loading">
					<BaseEchart :option="option" style="width: 100%; height: 295px; margin-left: 20px"></BaseEchart>
				</div>
			</div>
			<div element-loading-text="加载中..." element-loading-background="rgba(0, 0, 0, 0.4)" v-loading="loading" v-if="serviceIndex == 4 || serviceIndex == 3" style="position: relative">
				<div v-if="serviceIndex == 3" class="title_right">
					卫生人员总数：<span>{{ peopleTotal }}</span>
				</div>
				<BaseEchart :option="option" style="width: 100%; height: 224px"></BaseEchart>
			</div>
		</div>
		<div class="tab">
			<span :class="serviceIndex == 1 ? 'on' : ''" @click="selectService(1)">卫生机构总数</span>
			<em>|</em>
			<span :class="serviceIndex == 2 ? 'on' : ''" @click="selectService(2)">床位数</span>
			<em>|</em>
			<span :class="serviceIndex == 3 ? 'on' : ''" @click="selectService(3)">卫生人员总数</span>
			<em>|</em>
			<span :class="serviceIndex == 4 ? 'on' : ''" @click="selectService(4)">卫生总费用</span>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import { barChart, mutBarChart1, randomScaleByLevel } from '../common';
import { fetchRegionSummaryNotations, mapDataNormal } from '../../../request/api';

export default {
	props: ['dataType'],
	components: { BaseEchart, Title },
	data() {
		return {
			serviceIndex: 1,
			institutionData: [
				{ value: 21291, name: '医院', per: 0 },
				{ value: 27700, name: '社区卫生中心', per: 0 },
				{ value: 38600, name: '乡镇卫生院', per: 0 },
				{ value: 185400, name: '诊所', per: 0 },
				{ value: 634200, name: '村卫生室', per: 0 },
			],
			bedData: [
				{ value: 0, name: '县医院床位', per: 0 },
				{ value: 0, name: '乡卫生院床位', per: 0 },
				{ value: 0, name: '社区中心床位', per: 0 },
				{ value: 0, name: '诊所床位', per: 0 },
				{ value: 0, name: '其他床位', per: 0 },
			],
			data: [],
			loading: false,
			option: undefined,
			peopleTotal: 0,
			isShowSwitch: true,
			isSwitchOpen: true,
			period: 1,
		};
	},
	computed: {
		...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
		valueByLevel() {
			const scale = randomScaleByLevel(this.mapLevel);
			return this.institutionData.map((s) => {
				return {
					...s,
					value: Number((s.value / scale).toFixed(0)),
				};
			});
		},
		valueByLevelBed() {
			return this.bedData.map((s) => {
				return {
					...s,
					value: Math.round(s.value / (this.mapLevel + 1) / this.period),
				};
			});
		},
		pieTotal() {
			let sum = 0;
			this.valueByLevel.map((item) => {
				sum += item.value;
			});
			return sum;
		},
		pieTotalBed() {
			let sum = 0;
			this.data.map((item) => {
				sum += item.value;
			});
			return sum;
		},
		currentMonthPeriod() {
			return this.$store.state.homePage.currentMonthPeriod;
		},
		monthPeriod() {
			return this.$store.state.homePage.monthPeriod;
		},
	},
	watch: {
		mapLevel(newV, oldV) {
			this.init(this.serviceIndex);
		},
		mapDataArr() {},
		monthPeriod: {
			handler() {
				this.selectTime();
			},
		},
	},
	mounted() {
		this.selectTime();
		this.init(1);
	},
	methods: {
		async selectService(i) {
			await this.init(i);
      this.serviceIndex = i;
		},
		async init(index) {
			let colorOption;
			let dataOption;
			let titleOption;
			let pieTotal;
			if (index == 1) {
				colorOption = ['#F9A168', '#FBD75B', '#4EF5FF', '#4BFDCF', '#307BE3'];
				this.data = this.valueByLevel;
				dataOption = this.data;
				titleOption = '机构总数';
				pieTotal = this.pieTotal;
				this.$store.state.homePage.block.healthRes = pieTotal
			} else if (index == 2) {
				this.loading = true;
				let fetchData = await fetchRegionSummaryNotations({ itemName: '县医院床位;乡卫生院床位;社区中心床位;卫生室床位;诊所床位;其他床位;', fullTime: this.isSwitchOpen });
				this.loading = false;

				this.data = mapDataNormal(this.bedData, fetchData?.data?.collection || []);
				const sum = this.data.reduce((s, cur) => {
					s = s + cur.value;
					return s;
				}, 0);
				this.data = this.data.map((d) => {
					d.per = d.value / sum;
					return d;
				});
				colorOption = ['#FBD75B', '#4EF5FF', '#4BFDCF', '#307BE3'];
				dataOption = this.data;
				titleOption = '床位总数';
				pieTotal = this.pieTotalBed;
				this.$store.state.homePage.block.healthRes = pieTotal
			}
			if (index == 1 || index == 2) {
				this.option = {
					color: colorOption,
					legend: {
						show: false,
						bottom: 0,
						padding: [0, 0, 20, 0],
						formatter: function (name) {
							return '{a|' + name + '}';
						},
						icon: 'circle',
						itemWidth: 6,
						itemHeight: 6,
						textStyle: {
							color: 'white',
							backgroundColor: 'transparent',
							rich: {
								a: {
									width: 80,
									fontSize: 14,
								},
							},
						},
					},
					grid: {
						top: 0,
					},
					graphic: {
						elements: [
							{
								type: 'circle',
								shape: {
									r: 75,
								},
								style: {
									stroke: '#307BE3',
									lineWidth: 1,
								},
								left: 'center',
								top: 35,
							},
							{
								type: 'circle',
								shape: {
									r: 45,
								},
								style: {
									stroke: '#307BE3',
									lineWidth: 1,
								},
								left: 'center',
								top: 67,
							},
							{
								type: 'image',
								style: {
									image: require('../assets/pie-bg2.jpg'),
									width: 75,
									height: 75,
								},
								left: 'center',
								top: 75,
							},
							{
								type: 'text',
								left: '45%',
								top: 100,
								style: {
									fill: '#ffffff',
									text: titleOption,
									font: '12px sans-serif',
									textAlign: 'center',
								},
							},
							{
								type: 'text',
								left: 'middle',
								bounding: 'raw',
								left: 'center',
								top: 115,
								style: {
									fill: '#00F7F9',
									text: pieTotal,
									font: '20px sans-serif',
									textAlign: 'center',
								},
							},
						],
					},
					series: [
						{
							type: 'pie',
							top: -72,
							left: 'center',
							width: 260,
							radius: ['38%', '53%'],
							avoidLabelOverlap: true,
							itemStyle: {
								borderRadius: 20,
								borderColor: '#020C1D',
								borderWidth: 3,
							},
							label: {
								// alignTo: 'edge',
								formatter: '{name|{b}}\n{value|{c}}  {per|{d}%}',
								minMargin: 15,
								edgeDistance: 80,
								// margin:'-50',
								lineHeight: 12,
								rich: {
									name: {
										fontSize: 12,
										color: '#fff',
									},
									value: {
										fontSize: 10,
									},
								},
							},
							labelLine: {
								normal: {
									length: 15,
									length2: 25,
								},
							},
							data: dataOption,
						},
					],
				};
			} else if (index == 3) {
				this.loading = true;
				let fetchData = await fetchRegionSummaryNotations({ itemName: '卫生技术;其他技术;工勤技术;管理人员;', fullTime: this.isSwitchOpen });
				this.loading = false;
				let arry = [
					{ name: '卫生技术', value: 700 },
					{ name: '其他技术', value: 450 },
					{ name: '管理人员', value: 760 },
					{ name: '工勤技能', value: 500 },
					{ name: '村卫生室', value: 300 },
				];
				this.data = mapDataNormal(arry, fetchData?.data?.collection || []);
				const sum = this.data.reduce((sum, cur) => {
          sum = sum + cur.value
          return sum
        }, 0)
				this.peopleTotal = sum;
				this.$store.state.homePage.block.healthRes = sum
				this.option = barChart({ xData: this.data.map((d) => d.name), yData: this.data.map((d) => d.value) });
			} else if (index == 4) {
				let arry = [
					{ name: '政府卫生支出', value: 80 },
					{ name: '社会卫生支出', value: 45 },
					{ name: '个人卫生支出', value: 76 },
				];
				const dataX3 = arry.map((v, i) => {
					return { name: v.name, value: Math.round(v.value / (this.mapLevel + 1) / this.period)};
				});
				dataX3.map((item,i)=>{
					if(i==0){
            this.$store.state.homePage.block.healthRes=item.value*10
					}
				})
				this.option = mutBarChart1({
					leArr: ['城市', '乡村'],
					xData: dataX3.map((d) => d.name),
					yFirst: dataX3.map((d) => d.value * 10),
					ySecond: dataX3.map((d) => d.value * 8),
				});
			}
		},
		changeSwitch(val) {
			this.isSwitchOpen = val;
			this.selectTime();
		},
		selectTime() {
			if (this.isSwitchOpen) {
				this.period = this.monthPeriod;
			} else {
				this.period = this.currentMonthPeriod;
			}
			this.init(this.serviceIndex);
		},
	},
};
</script>

<style lang="scss" scoped>
.basicDataClass {
	background: rgba(5, 41, 92, 0.6);
	border: 2px solid rgba(17, 94, 205, 0.2);
	height: 290px;

	.main {
		height: calc(100% - 70px);

		.title_right {
			position: absolute;
			right: 15px;
			top: 15px;
			font-size: 14px;
			text-align: center;

			span {
				color: #00f0ff;
				font-size: 20px;
			}
		}
	}

	.tab {
		width: 100%;
		height: 34px;
		line-height: 34px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		border-top: 1px solid #213861;
		display: flex;

		span {
			flex: 1;
			display: inline-block;
			height: 100%;
			position: relative;
			cursor: pointer;
		}

		em {
			font-style: normal;
			color: #213861;
		}

		.on {
			color: #00f0ff;
		}
	}
}
</style>

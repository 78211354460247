
<template>
	<div class="eventStatic">
		<Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch">妇幼卫生</Title>
		<div class="content">
			<div v-if="serviceIndex == 1" class="health">
				<div v-for="(item, index) in healthListByLevel" class="item">
					<p>{{ item.name }}</p>
					<div :class="index == 0 || index == 2 ? 'blue' : 'green'">
						<countTo :startVal="0" :endVal="item.value" :duration="CountAnimationDuration"></countTo>%
					</div>
				</div>
			</div>
			<div v-if="serviceIndex == 2" class="child">
				<div v-for="item in childListByLevel" class="item">
					<p>{{ item.name }}</p>
					<p><span>
							<countTo :startVal="0" :endVal="item.value" :duration="CountAnimationDuration"></countTo>%
						</span></p>
					<p class="line"></p>
					<div class="type">
						<div>
							<p>城市</p>
							<p>
								<countTo :startVal="0" :endVal="item.value1" :duration="CountAnimationDuration"
									style="color: #fff;"></countTo>%
							</p>
						</div>
						<div style="width: 1px;height: 14px;background-color: #26477A;"></div>
						<div>
							<p>农村</p>
							<p>
								<countTo :startVal="0" :endVal="item.value2" :duration="CountAnimationDuration"
									style="color: #fff;"></countTo>%
							</p>
						</div>
					</div>
				</div>
			</div>
			<div v-if="serviceIndex == 3" class="child">
				<div class="left">
					<BaseEchart :option="chinaChartOptions" style="height: 150px; width:150px"></BaseEchart>
				</div>
				<div class="right">
					<div>
						<p>城市</p>
						<p><span>
								<countTo :startVal="0" :endVal="pregnantListByLevel[0].value1"
									:duration="CountAnimationDuration"></countTo>%
							</span></p>
					</div>
					<div>
						<p>农村</p>
						<p><span>
								<countTo :startVal="0" :endVal="pregnantListByLevel[0].value2"
									:duration="CountAnimationDuration"></countTo>%
							</span></p>
					</div>
				</div>
			</div>
		</div>
		<div class="tab">
			<span :class="serviceIndex == 1 ? 'on' : ''" @click="selectService(1)">妇幼保健</span>
			<em>|</em>
			<span :class="serviceIndex == 2 ? 'on' : ''" @click="selectService(2)">儿童死亡率</span>
			<em>|</em>
			<span :class="serviceIndex == 3 ? 'on' : ''" @click="selectService(3)">孕产妇死亡率</span>
		</div>
	</div>
</template>

<script>
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common'
import Title from '../components/Title';
import { mapState } from 'vuex';
import * as echarts from 'echarts';
import BaseEchart from '@/components/BaseEchart';

export default {
	components: {
		countTo,
		Title,
		BaseEchart
	},
	data() {
		return {
			CountAnimationDuration,
			serviceIndex: 1,
			chinaChartOptions: null,
			healthList: [
				{ name: '孕产妇产前检查率', value: 85 },
				{ name: '产后访视率', value: 62 },
				{ name: '住院分娩率', value: 90 },
				{ name: '3岁以下儿童系统管理率', value: 45 },
			],
			childList: [
				{ name: '5岁以下儿童死亡率', value: 15, value1: 5, value2: 10 },
				{ name: '婴儿死亡率', value: 20, value1: 5, value2: 15 },
				{ name: '新生儿死亡率', value: 22, value1: 10, value2: 12 },
			],
			pregnantList: [
				{ name: '孕产妇死亡率', value: 15, value1: 4, value2: 11 },
			],
			isShowSwitch: true,
			isSwitchOpen: true,
			period: 1
		}
	},
	computed: {
		...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
		valueByLevel() {
			return this.value / (this.mapLevel + 1)
		},
		healthListByLevel() {
			return this.healthList.map((item,index) => {
				return {
					...item,
					value: Math.round(item.value - ((this.mapLevel + 1) * 5)/this.period)
				}
			})
		},
		childListByLevel() {
			return this.childList.map(item => {
				return {
					...item,
					value: Math.round(item.value - ((this.mapLevel) * 3))/this.period,
					value1: Math.round(item.value1 - ((this.mapLevel) * 1))/this.period,
					value2: Math.round(item.value2 - ((this.mapLevel) * 2))/this.period
				}
			})
		},
		pregnantListByLevel() {
			return this.pregnantList.map(item => {
				return {
					...item,
					value: Math.round(item.value - ((this.mapLevel + 1) * 3)),
					value1: Math.round(item.value1 + ((this.mapLevel + 1) * 1)/this.period),
					value2: Math.round(item.value2 + ((this.mapLevel + 1) * 2)/this.period)
				}
			})
		},
		currentMonthPeriod() {
			return this.$store.state.homePage.currentMonthPeriod
		},
		monthPeriod() {
			return this.$store.state.homePage.monthPeriod
		},
	},
	mounted() {
		this.selectTime()
	},
	watch: {
		mapLevel: {
			handler() {
				this.init()
			}
		},
		monthPeriod: {
			handler() {
				this.selectTime()
			},
		},
		healthListByLevel:{
			handler(val){
				this.$store.state.homePage.block.puerperaHealth = val[0].value
			},
			immediate:true
		}
	},
	methods: {
		selectService(i) {
			this.serviceIndex = i
			if(i==3){
				this.init()	
				this.$store.state.homePage.block.puerperaHealth = Math.round(this.pregnantList[0].value + (this.mapLevel + 1) * 3/this.period)
			}else if(i==2){
				this.$store.state.homePage.block.puerperaHealth = this.childListByLevel[0].value
			}else{
				this.$store.state.homePage.block.puerperaHealth = this.healthListByLevel[0].value
			}
		},
		init() {
			let value = Math.round(this.pregnantList[0].value + (this.mapLevel + 1) * 3/this.period)
			this.chinaChartOptions = {
				backgroundColor: "",
				title: [{
					text: '{a|' + value + '}{c|%}',
					x: 'center',
					y: '30%',
					textStyle: {
						rich: {
							a: {
								fontSize: 24,
								color: '#00FFFF',
								fontWeight: 'bold'
							},
							c: {
								fontSize: 24,
								color: '#00FFFF',
								fontWeight: 'normal'
							}
						}
					}
				},
				{
					text: "孕产妇死亡率",
					x: "center",
					y: "53%",
					textStyle: {
						fontSize: 14,
						fontWeight: "normal",
						color: "#fff",
					},
				}
				],
				series: [
					//外环
					{
						name: "外环",
						type: 'pie',
						silent: true,
						clockwise: true,
						radius: ['83%', '85%'],
						label: {
							show: false,
						},
						labelLine: {
							show: false
						},
						itemStyle: {
							color: '#0A2A55',
						},
						data: [0]
					},
					//内环
					// {
					// 	name: "内环",
					// 	type: 'pie',
					// 	silent: true,
					// 	clockwise: true,
					// 	radius: ['76%', '77%'],
					// 	label: {
					// 		show: false,
					// 	},
					// 	labelLine: {
					// 		show: false
					// 	},
					// 	itemStyle: {
					// 		color: '#0A2A55',
					// 	},
					// 	data: [0]
					// },
					//外环
					{
						name: '',
						type: 'pie',
						radius: ['63%', '75%'],
						silent: true,
						clockwise: true,
						startAngle: 90,
						label: {
							show: false,
						},
						data: [
							{
								value: value,
								itemStyle: {
									normal: {
										//外环发光
										borderWidth: 0.5,
										shadowBlur: 20,
										borderColor: '#4bf3f9',
										// shadowColor: '#9bfeff',
										color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
											offset: 0,
											color: '#0290DB'
										}, {
											offset: 1,
											color: '#48E465'
										}]),
									}
								}
							},
							{
								value: 100 - value,
								label: {
									normal: {
										show: true
									}
								},
								itemStyle: {
									normal: {
										color: "#0A2A55"
									}
								}
							}
						]
					},
				]
			};
		},
		changeSwitch(val) {
			this.isSwitchOpen = val
			this.selectTime()
		},
		selectTime() {
			if (this.isSwitchOpen) {
				this.period = this.monthPeriod
			} else {
				this.period = this.currentMonthPeriod
			}
			this.init()
		}
	}
};
</script>

<style lang="scss" scoped>
.eventStatic {
	border: 2px solid #264471;
	box-sizing: border-box;
	background: rgba(5, 41, 92, .6);
	border: 2px solid rgba(17, 94, 205, .2);

	.content {
		width: 100%;
		height: 147px;

		.health {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-evenly;

			.item {
				height: 100%;
				width: 85px;
				padding-top: 10px;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				p {
					height: 37px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				div {
					width: 85px;
					height: 75px;
					line-height: 50px;
					font-size: 26px;
					margin-bottom: 10px;
				}

				.blue {
					background: url('../../../assets/imgs/icon2.png') no-repeat center;
				}

				.green {
					background: url('../../../assets/imgs/icon3.png') no-repeat center;
				}
			}
		}

		.child {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.item {
				width: 135px;
				height: 120px;
				background-color: #0C3363;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;

				span {
					font-size: 20px;
					color: #00F0FF;
				}

				.line {
					width: 60px;
					height: 1px;
					background-color: #26477A;
				}

				.type {
					display: flex;
					width: 100%;
					justify-content: space-around;
					align-items: center;
					padding-left: 5px;
				}
			}

			.left {
				width: 50%;
				height: 90%;
				background-color: #0C3363;
				display: flex;
				justify-content: center;
			}

			.right {
				width: 45%;
				height: 90%;
				background-color: #0A284F;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				div {
					width: 95%;
					height: 45%;
					background-color: #0C3363;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-evenly;
					font-size: 14px;

					span {
						color: #00F0FF;
						font-size: 24px;
					}
				}
			}
		}
	}
}

.tab {
	width: 100%;
	height: 34px;
	line-height: 34px;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	border-top: 1px solid #213861;
	display: flex;

	span {
		flex: 1;
		display: inline-block;
		height: 100%;
		position: relative;
		cursor: pointer;
	}

	em {
		font-style: normal;
		color: #213861;
	}

	.on {
		color: #00F0FF;
	}
}
</style>
<template>
	<div class="analysis m_xcus" v-if="patter.type == 2">
		<span class="leftArrow"></span>
		<div class="our">
			<em>{{ patter.title }}</em>
			<countTo class="num" :startVal="0" :endVal="value" :duration="CountAnimationDuration"></countTo>
		</div>
		<span class="rightArrow"></span>
	</div>
	<div class="analysis" v-else>
		<span class="leftArrow"></span>共计 <countTo class="num" :startVal="0" :endVal="value" :duration="CountAnimationDuration"></countTo> {{genre}}<span
			class="rightArrow"
		></span>
	</div>
</template>
<script>
import { CountAnimationDuration } from '@/views/homePage/common';
import countTo from 'vue-count-to';

export default {
  components: {countTo},
	props: {
		value: {
			type: Number,
			default: false,
		},
		genre: {
			type: String,
			default: '人',
		},
		patter: {
			type: Object,
			default: () => ({type: 1, title: ''}),
		},
	},
  data() {
    return {
      CountAnimationDuration
    }
  },
};
</script>
<style lang="scss" scoped>

.analysis {
	display: flex;
	justify-content: center;
	font-size: 18px;
	align-items: center;
	margin-top: 24px;
	margin-bottom: 24px;
	.leftArrow {
		display: inline-block;
		margin-right: 18px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 0 8px 13.9px;
		border-color: transparent transparent transparent #fbea56;
	}
	.rightArrow {
		display: inline-block;
		margin-left: 18px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 13.9px 8px 0;
		border-color: transparent #fbea56 transparent transparent;
	}
	.our{
		display: flex;
		flex-direction: column;
		align-items: center;
		em{
			font-size: 16px;
			font-style: normal;
			color: #FFFFFF;
			padding-bottom: 16px;
		}
	}
	.num {
		font-family: 'ABDUCTION2000';
		padding: 0 12px;
		font-size: 34px;
		color: #00ffff;
	}
}
.m_xcus{
	align-items: flex-start;
	margin: 14px 0;
	.leftArrow, .rightArrow{ margin-top: 15px;}
}
</style>

<template>
	<div>
		<el-row class="page" style="margin: 0" :gutter="16">
			<el-col :span="6">
				<BasicDataTotal @dataTypeChange="onDataTypeChange1" :dataType="dataType1" ref="basicDataTotal" class="mb16" />
				<LeftCenter class="mb16" />
				<LeftBottom ref="basicDataTotal" class="mb16" />
			</el-col>
			<el-col :span="12">
				<StatisticsBar :data="statisticsList"></StatisticsBar>
				<MapData :mapType="1" class="mb16 mt16" :height="664" :mapindex="1" />
				<div class="m_xtrend mt16">
					<div class="title">
						<div class="indicator"></div>
						<el-select v-model="lineTit" placeholder="请选择" @change="curveFun($event)">
							<el-option v-for="(v, i) in optionTit" :key="i" :label="v.label" :value="v.value">
							</el-option>
						</el-select>
					</div>
					<BaseEchart :option="option" style="width: 100%; height: 156px;"></BaseEchart>
				</div>
			</el-col>
			<el-col :span="6">
				<BasicMedical ref="basicMedical" class="mb16" />
				<RightCenter class="mb16" />
				<EventStatic ref="eventStatic" class="mb16" />
			</el-col>
			<div class="content_wrap" v-if="isshowList">
			<div class="content">
				<div class="content-title">
					<div class="label">人员列表</div>
					<div class="close" @click="close">
					<img src="../../../assets/imgs/visitCloseBtn.png" alt="" />
					</div>
				</div>
				<div class="content-text">
					<div class="personInfo">
					<div class="personInfo-doctor" v-for="item in doctorList" >
						<div class="doctorName">
							<div>
								{{item.name}}（{{item.job}}）{{item.age}}岁
							</div>
							<div >
								岗位：{{item.place}} <em>|</em> 联系电话：{{item.phone}}
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
		</div>
		</el-row>
	</div>
</template>
<script>
import '@/assets/icon-font/ABDUCTION2000.css';
import dayjs from 'dayjs'
import BasicDataTotal from './basicDataTotal.vue';
import LeftCenter from './LeftCenter.vue';
import RightCenter from './RightCenter.vue';
import BasicMedical from './basicMedical.vue';
import EventStatic from './eventStatic.vue';
import LeftBottom from './LeftBottom.vue';
import ImportantKey from './importantKey.vue';
import MapData from '@/views/homePage/overview/mapData.vue';
import { formatTimestamp } from '@/utils/formatTime.js';
import StatisticsBar from '../components/StatisticsBar.vue'
import { mapState } from 'vuex'
import BaseEchart from '@/components/BaseEchart';
import {getGlobalParams} from '@/views/homePage/common'

export default {
	name: 'home',
	components: {
		StatisticsBar,
		BasicDataTotal,
		LeftCenter,
		RightCenter,
		BasicMedical,
		EventStatic,
		LeftBottom,
		ImportantKey,
		MapData,
		BaseEchart
	},
	data() {
		return {
			currentTime: [],
			timer: null,
			dataType: 'china',
			dataType1: 'institutions',
			statisticsList: [],
			first: [{
				name: '卫生机构总数',
				value: [180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280, 180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280]
			}, {
				name: '床位数',
				value: [210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100, 210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100]
			},{
				name: '卫生人员总数',
				value: [0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40, 0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40]
			}, {
				name: '卫生总费用',
				value: [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0]
			}],
			second: [{
				name: '新型农村合作医疗',
				value: [210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100, 210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100]
			},{
				name: '农村三级医疗效劳体系',
				value: [0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40, 0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40]
			}, {
				name: '农村医疗效劳',
				value: [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0]
			}],
			third: [{
				name: '门诊人均费用',
				value: [180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280, 180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280]
			},{
				name: '住院人均费用',
				value: [0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40, 0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40]
			}, {
				name: '药费占比',
				value: [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0]
			}],
			fourth: [{
				name: '总诊疗人数',
				value: [180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280, 180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280]
			}, {
				name: '病床使用',
				value: [210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100, 210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100]
			}, {
				name: '医师工作负荷',
				value: [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0]
			}],
			fifth: [{
				name: '社区卫生效劳体系',
				value: [180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280, 180, 240, 160, 190, 130, 240, 10, 100, 160, 80, 45, 280]
			},{
				name: '社区医疗效劳',
				value: [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0],
			}],
			sixth: [{
				name: '妇幼保健',
				value: [210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100, 210, 220, 180, 23, 150, 260, 140, 120, 180, 100, 65, 100]
			},{
				name: '儿童死亡率',
				value: [0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40, 0, 280, 70, 230, 170, 280, 10, 140, 200, 120, 85, 40]
			}, {
				name: '孕产妇死亡率',
				value: [60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0, 60, 14, 60, 170, 110, 220, 250, 80, 140, 60, 85, 0]
			}],
			lineArr: [],
			lineTit: '卫生资源增长',
			optionTit: [{
				value: '卫生资源增长',
				label: '建设卫生资源增长趋势'
			}, {
				value: '农村卫生',
				label: '建设农村卫生增长趋势'
			}, {
				value: '病人医药费用',
				label: '建设病人医药费用增长趋势'
			}, {
				value: '医疗效劳',
				label: '建设医疗效劳增长趋势'
			}, {
				value: '社区卫生',
				label: '建设社区卫生增长趋势'
			}, {
				value: '妇幼卫生',
				label: '建设妇幼卫生增长趋势'
			}],
			color : [
				{up: '#0EB7F4', down: 'rgba(14,183,244,.1)'},
				{up: '#FF7B00', down: 'rgba(255,123,0,.1)'},
				{up: '#58E41B', down: 'rgba(88,228,27,.1)'},
				{up: '#F4AB0E', down: 'rgba(244,171,14,.1)'},
			],
			isshowList:false,
			doctorList: [
				{
					name: '张某某',
					age: '33',
					job: '副主任医师',
					place: '内科医生',
					phone:'18340910447'
				},
				{
					name: '赵某某',
					age: '30',
					job: '副主任药师',
					phone:'18340910447',
					place: '内科医生'
				},
				{
					name: '刘某某',
					age: '25',
					job: '主任药师',
					place: '内科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
				{
					name: '刘某某',
					age: '25',
					job: '副主任医师',
					place: '外科医生',
					phone:'18340910447'

				},
			],
		};
	},
	beforeCreate() {
		this.$store.state.homePage.heads = { name: '', index: 1 };
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
		statisticsListByLevel() {
			return this.statisticsList.map(s => {
				return {
					...s,
					num: s.num / (this.mapLevel + 1)
				}
			})
		},
		heads() {
			return this.$store.state.homePage.heads
		},
		timeRange() {
			return this.$store.state.homePage.timeRange
		},
		timeRangeAndMapLevel() {
			return this.timeRange + this.mapLevel
		},
		block() {
			return this.$store.state.homePage.block
		},
	},
	watch: {
		$route: {
			handler(val) {
				if (val) {
					this.$store.state.themeConfig.themeConfig.isCollapse = true;
				}
			},
			deep: true,
			immediate: true,
		},
		mapLevel: {
			handler(val) {
				if (val != 0) {
					this.dataType = this.dataType == 'china' || this.dataType == '总览' ? '总览' : '细分'
				} else {
					this.dataType = this.dataType == '总览' ? 'china' : 'province'
				}
				if (this.lineArr.length == 0) this.lineArr = this.first
				let as = this.color
				this.lineArr.map((v, i) => { v.color = as[i] })
				this.wireFun()
			},
			immediate: true
		},
		timeRangeAndMapLevel: {
			handler(val) {
				let as = this.color
				this.lineArr.map((v, i) => { v.color = as[i] })
				this.wireFun()
			},
			immediate: true
		},
		heads: {
			immediate: true,
			handler: function (val) {
				if (val.name != '') {
					this.curveFun(val)
				}
			}
		},
		block: {
			handler: function (val) {
				this.statisticsList = [
					{ label: '卫生资源', num: val.healthRes },
					{ label: '农村卫生', num: val.ruralHealth },
					{ label: '病人医药费用', num: val.medExpense },
					{ label: '医疗效劳', num: val.medService },
					{ label: '社区卫生', num: val.commHealth },
					{ label: '妇幼卫生', num: val.puerperaHealth },
				]
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.timer = null;
		this.timer = setInterval(() => {
			this.getCurrentTime();
		}, 1000);
		this.bus.$on('show_doctor', (res) => {
			if(res==1){
				this.isshowList = true
			}
		})
	},
	methods: {
		onDataTypeChange(e) {
			this.dataType = e
		},
		onDataTypeChange1(e) {
			this.dataType1 = e
		},
		getCurrentTime() {
			let data = new Date();
			let res = formatTimestamp(data);
			res = res.split(' ');
			this.currentTime = [res[0], res[1]];
		},
		deal(arr) {
			return arr.map(d => {
				return Math.round(d / (this.mapLevel + 1))
			})
		},
		wireFun() {
			let data = []
			let start = this.timeRange[0]
			let end = this.timeRange[1]
			let months = []
			while(start <= end){
				months.push(start.getMonth() + 1)
				start = dayjs(start).add(1, 'month').toDate()
			}
			let us = {}
			this.lineArr.map(v => {
				us = {
					name: v.name,
					data: this.deal(v.value),
					symbol: 'circle',
					type: 'line',
					smooth: true,
					symbol: "none",
					lineStyle: {
						color: v.color.up,
					},
					itemStyle: {
						color: v.color.up
					},
					areaStyle: {
						color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [{
								offset: 0, color: v.color.up // 0% 处的颜色
							}, {
								offset: 1, color: v.color.down // 100% 处的颜色
							}],
						}
					}
				}
				data.push(us)
			})
			this.option = ({
				axisLabel: {
					color: '#fff',
					fontSize: 12
				},
				legend: {
					show: true,
					icon: 'stack',
					top: 6,
					itemWidth: 10,
					itemHeight: 5,
					itemColor: '#fff',
					textStyle: {
						fontSize: 16,
						color: '#fff', // 设置字体颜色
						padding: [0, 5] // 设置文字与图例的距离
					},
					itemGap: 20,// 设置图例项之间的间距
					itemStyle: {
						borderWidth: 0,
					}
				},
				grid: {
					left: 40,
					right: 40,
					top: 48,
					bottom: 32
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					splitLine: {
						show: true,
						lineStyle: {
							opacity: 0.2,
							type: [5, 5],
							dashOffset: 0,
							shadowBlur: 0
						},
					},
					data: months.map(m => m + '月')
				},
				yAxis: {
					type: 'value',
					splitNumber: 4,
					min: 0,
					// max: 280,
					splitLine: {
						show: true,
						lineStyle: {
							opacity: 0.2,
							type: [5, 5],
							dashOffset: 0,
							shadowBlur: 0
						},
					},
				},
				series: data
			})
			this.$forceUpdate();
		},
		curveFun(val) {
			let name = val
			if(typeof val == 'object'){
				name = val.name
			}
			this.lineTit = name
			let arr = []
			if (name == '卫生资源') {
				arr = this.first
			} else if (name == '农村卫生') {
				arr = this.second
			} else if (name == '病人医药费用') {
				arr = this.third
			} else if (name == '医疗效劳') {
				arr = this.fourth
			} else if (name == '社区卫生') {
				arr = this.fifth
			} else if (name == '妇幼卫生') {
				arr = this.sixth
			}
			let as = this.color
			arr.map((v, i) => { v.color = as[i] })
			this.lineArr = arr
			this.wireFun()
		},
		changeFun(val){
			console.log(val)
		},
		close() {
			this.isshowList = false
		},
	},
	destroyed() {
		clearInterval(this.timer);
		this.$store.state.homePage.heads = { name: '', index: 1 };
	},
};
</script>
<style lang="scss" scoped>
.homePage {
	background: url('../../../assets/imgs/bj.png') no-repeat center;
	background-size: cover;

	.fullBtn {
		margin-bottom: 15px;

		/deep/ .el-button {
			padding: 8px 30px;
			margin-right: 30px;
		}
	}

	&-content {
		background: url('../../../assets/imgs/fullBorder.png') no-repeat center;
		background-size: 100% 100%;
		overflow: auto;

		.images {
			position: relative;
			top: -8px;

			.titleLineLight {
				position: absolute;
				left: 0;
				top: -35px;
			}
		}

		.header-content {
			height: 78px;
			padding: 0 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.left {
				display: flex;
				align-items: center;

				.linghtClass {
					display: inline-block;
					width: 15px;
					height: 15px;
					background-color: #fff;
					box-shadow: 0 0 10px #0db1f4, 0 0 20px #0db1f4, 0 0 30px #0db1f4, 0 0 40px #0db1f4, 0 0 70px #0db1f4, 0 0 80px #0db1f4, 0 0 100px #0db1f4,
						0 0 150px #0db1f4;
					margin-right: 16px;
				}
			}
		}

		/deep/ .el-row {
			margin: 0;
		}
	}
}

.m_xtrend {
	background: rgba(5, 41, 92, .3);
	position: relative;
	border: 2px solid #1D3468;

	.title {
		position: absolute;
		left: 0;
		top: 0;
		height: 32px;
		padding-left: 16px;
		font-size: 18px;
		display: flex;
		align-items: center;
		z-index: 10;
		.indicator {
			height: 16px;
			width: 4px;
			margin-right: 8px;
			background-image: linear-gradient(#308FCC, #224ABE);
		}
	}
	/deep/ .el-input__inner{
		background: none;
		font-size: 16px;
		color:#fff;
		font-weight: bold;
		padding: 0;
		border: none;
	}
	/deep/ .el-input__icon{
		line-height: 32px;
		color:#1C87FF;
	}
}

/deep/ div::-webkit-scrollbar {
	width: 4px !important;
	/*滚动条宽度*/
	background-color: #031227 !important;
	/*滚动条背景颜色*/
	height: 394px !important;
	/*滚动条高度*/
}

/deep/ div::-webkit-scrollbar-thumb {
	box-shadow: 0px 1px 3px #203a66 inset !important;
	/*滚动条的内阴影*/
	border-radius: 10px !important;
	/*滚动条的圆角*/
	background-color: #203a66 !important;
	/*滚动条的背景颜色*/
}
.content_wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	margin: 0;
	background-color: rgba(145, 142, 142, 0.233);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 222;

	.content {
		width: 400px;
		height: 550px;
		background: url(../../../assets/imgs/visitGroupBg.png) no-repeat;
		background-size: 500px 550px;
		&-title {
			height: 52px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;
			background: url('../../../assets/imgs/dialogTitleBg.png') no-repeat center;
			background-size: 100% 100%;
			color: #fff;
		}

		.label {
			margin-top: 6px;
			font-size: 24px;
			line-height: 22px;
			height: 25px;
			background: url('../../../assets/imgs/线.png') no-repeat bottom;
			background-size: 100% 5px;
		}

		.close {
			display: flex;
			align-items: center;
			margin-top: 6px;

			img {
				width: 28px;
				height: 28px;
				cursor: pointer;
			}
		}

		&-text {
			max-height: 490px;
			overflow-y: auto;

			.personInfo {
				padding: 0 18px;
				padding-top: 16px;

				&-doctor {
					background: rgba(12, 49, 94, 0.65);
					border-radius: 6px;
					display: flex;
					position: relative;
					margin-top: 5px;


					.doctorName {
						padding: 15px 0;
						color: #fff;
						height: 70px;
						display: flex;
						flex-direction: column;
						text-indent: 2em;
						justify-content: space-around;
						div{
							width: 100%;
						}
						div:nth-child(2){
							font-size: 14px;
							font-weight: 300;
							em{
								font-style: normal;
								margin: 5px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
<template >
  <div class="card">
    <countTo :startVal="0" :endVal="value" :duration="1000" class="value"></countTo>
    <div class="name">{{ name }}</div>
  </div>
</template>
<script>
import countTo from 'vue-count-to';

export default {
  props: ['name', 'value'],
  components: {countTo}
}
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  height: 70px;
  // background-color: #0C285C;
  background: rgba(28,135,255,0.1);

  .value {
    font-size: 20px;
    color: #00FFFF;
  }

  .name {
    font-size: 12px;
    color: white;
    max-width: 95%;
    text-align: center;
  }
}
</style>